<template>
  <div id="post-offices">
    <van-nav-bar
      :title="$t('post_offices.title')"
      left-arrow
      @click-left="() => $router.back()"
    />
    <van-search
      v-model="keyword"
      :placeholder="$t('common.input_search_keyword')"
    />
    <van-index-bar :index-list="indexList" v-if="postOffices">
      <template v-for="(items, key) in postOffices">
        <van-index-anchor :key="key" :index="key">{{ key }}</van-index-anchor>
        <van-cell class="postoffice-cell" v-for="item in items" :key="item.id">
          <ul class="postoffice-info">
            <li>{{ item.name }}</li>
            <li><van-icon name="wap-home-o" />{{ item.address }}</li>
            <li v-if="item.phone" @click.prevent="callPhone(item.phone)"><van-icon name="phone-o" />{{ item.phone }}</li>
          </ul>
        </van-cell>
      </template>
    </van-index-bar>
  </div>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import { mapState } from 'vuex';
import {
  IndexBar, IndexAnchor, NavBar, Search,
} from 'vant';
import { Probability } from '@/helpers';

Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(NavBar);
Vue.use(Search);

export default {
  name: 'PostOffices',
  data() {
    return {
      keyword: '',
    };
  },
  mounted() {
    (new Probability({
      p: this.postOfficeList.length ? '50%' : '100%',
      f: () => this.$store.dispatch('fetchPostOffice'),
    }))();
  },
  computed: {
    ...mapState({
      postOfficeList: state => state.address.postOfficeList,
    }),
    postOffices() {
      if (this.postOfficeList) {
        let list = [];
        if (this.keyword) {
          this.postOfficeList.forEach((item) => {
            if (~item.name.indexOf(this.keyword) || ~item.address.indexOf(this.keyword)) {
              list.push(item);
            }
          });
        } else {
          list = this.postOfficeList;
        }
        return _.groupBy(list, 'index_name');
      }
      return [];
    },
    indexList() {
      return _.keys(this.postOffices);
    },
  },
  methods: {
    callPhone(phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    },
  },
};
</script>

<style lang="scss">
  #post-offices {
    .postoffice-cell {
      .postoffice-info {
        li {
          font-size: 12px;
          width: 70%;
          &:first-child {
            font-weight: bold;
            font-size: 14px;
          }
        }
      }
    }
  }
</style>
